import { Controller } from '@hotwired/stimulus';
import I18n from '../app/i18n';

export default class extends Controller {
  static targets = [
    'summary',
    'summaryCounter',
    'image',
    'imageField',
  ];
 
  static values = {
    domain: String,
    siteId: Number,
  }

  connect() {
    this.refreshSummaryCount();

    if (this.imageFieldTarget.value != '') readURL(this.imageFieldTarget);
  }

  readURL(input) {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => this.imageTarget.setAttribute('src', e.target.result);
      reader.readAsDataURL(input.files[0])
    }  
  }   

  loadImage() {
    this.readURL(this.imageFieldTarget);
  }

  refreshSummaryCount() {
    const limit = 100;
    const wordCount = this.summaryTarget.value.length;
    let remaining = limit - wordCount;
    remaining = remaining < 0 ? 0 : remaining;
    if (this.summaryCounterTarget) {
      this.summaryCounterTarget.textContent = `${remaining}  ${I18n.t('characters_remaining')}`;
    }
  }
}