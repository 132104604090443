import { Controller } from '@hotwired/stimulus';
import * as bootstrap from 'bootstrap';

export default class extends Controller {

  connect() {
    const eventSelect = document.querySelector('#facebook_pixel_event_select');
    const submitBtn = document.querySelector('#facebook_pixel_event_submit');
    const eventsList = document.querySelector('#facebook_pixel_events_list');
    const deleteBtns = document.querySelectorAll('.pixel-event-delete-btn');
    const eventPreviewEl = document.querySelector('#facebook_pixel_event_preview');
    const controllerName = document.body.dataset.controller;
    const _this = this;

    if (eventSelect) {
      eventSelect.addEventListener('change', function(e) {
        e.preventDefault();

        if (e.target.value === '') {
          submitBtn.disabled = true;
        } else {
          submitBtn.disabled = false;
        }

        eventPreviewEl.innerText = e.target.value;
      });
    }

    if (submitBtn) {
      submitBtn.addEventListener('click', function(e) {
        e.preventDefault();

        const eventContent = eventSelect.value;

        if (eventContent === '') {
          return;
        }

        const eventContainer = document.createElement('div');
        eventContainer.classList.add(
          'd-flex', 'justify-content-between', 'align-items-center', 'mb-2',
        );
        const eventInput = document.createElement('input');
        eventInput.type = 'text';
        eventInput.classList.add('form-control');
        eventInput.value = eventContent;
        eventInput.name = controllerName === 'sites'
          ? 'site[facebook_pixel_events][]'
          : 'page[facebook_pixel_events][]';
        const eventDeleteBtn = document.createElement('button');
        eventDeleteBtn.type = 'button';
        eventDeleteBtn.classList.add(
          'btn',
          'btn-link',
          'text-danger',
          'ms-2',
          'pixel-event-delete-btn',
          'text-decoration-none',
        );
        eventDeleteBtn.innerHTML = '<i class="fe fe-x"></i>' ;
        eventDeleteBtn.addEventListener('click', function(e) {
          e.preventDefault();
          eventContainer.remove();
        });
        eventContainer.appendChild(eventInput);
        eventContainer.appendChild(eventDeleteBtn);
        eventsList.appendChild(eventContainer);

        _this.removeEmptyInput(eventsList);

        eventSelect.value = '';
        eventPreviewEl.textContent = '';

        const modalEl = document.getElementById('facebookPixelEvents');
        const modal = bootstrap.Modal.getInstance(modalEl);
        modal.hide();
      });
    }

    deleteBtns.forEach((deleteBtn) => {
      deleteBtn.addEventListener('click', function(e) {
        e.preventDefault();
        deleteBtn.parentElement.remove();

        if (eventsList.children.length === 0) {
          _this.addEmptyInput(controllerName, eventsList);
        }
      });
    });
  }

  addEmptyInput(controllerName, eventsList) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.value = '';
    input.id = 'facebook_pixel_events_empty';
    input.name = controllerName === 'sites'
      ? 'site[facebook_pixel_events]'
      : 'page[facebook_pixel_events]';
    eventsList.appendChild(input);
  }

  removeEmptyInput(eventsList) {
    const input = eventsList.querySelector('#facebook_pixel_events_empty');
    if (input) input.remove();
  }

}